<template>
	<div class="bg-white px-3 goods_create" style="margin: -20px;margin-top: -1rem;margin-bottom: 0!important;">
		<router-link :to="{ name: 'cklb_list_list' }" style="position: absolute;top: 12px;left: 200px;"><el-button size="mini">回到仓库列表</el-button></router-link>

		<el-form ref="form" label-width="80px">
			<el-form-item label="物料名称" style="padding-top: 20px;"><el-input v-model="form.mc" class="w-50"></el-input></el-form-item>

			<el-form-item label="保质期">
				<el-select v-model="form.bzq" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="物料数量"><el-input-number v-model="form.num" :disabled="true"></el-input-number></el-form-item>
			
			<el-form-item label="预警数量"><el-input-number v-model="form.yj"></el-input-number></el-form-item>
			
			<el-form-item label="新拿货价"><el-input-number v-model="form.nhj" :disabled="true"></el-input-number></el-form-item>
			
			<el-form-item label="操作" v-if="id > 0">
				<el-radio v-model="form.jczt" label="1">存入仓库</el-radio>
				<el-radio v-model="form.jczt" label="2">取出仓库</el-radio>
			</el-form-item>
			
			<el-form-item label="数量" v-if="id > 0">
				<div v-if="form.jczt == 1" class="d-flex">
					<el-input-number v-model="form.nums" :min="0"></el-input-number>
					<div class="ml-5 mr-2">拿货价</div>
					<el-input-number v-model="form.nhjs" :min="0"></el-input-number>
				</div>
				<el-input-number v-else v-model="form.nums" :min="0" :max="form.num"></el-input-number>
				
			</el-form-item>
			
			<el-form-item label="物料流水" v-if="id > 0">
				<div>
					<el-table border :data="form.nhlist" style="width:90%" size="mini">
						<el-table-column width="45" align="center" label="编号">
							<template slot-scope="scope">
								<div>{{scope.row.id}}</div>
							</template>
						</el-table-column>
						
						<el-table-column align="center" label="数量">
							<template slot-scope="scope">
								<div v-if="scope.row.jczt == 1" style="background: #00cf33;color: #fff;">{{scope.row.num}}</div>
								<div v-else style="background: #cf0003;color: #fff;">{{scope.row.num}}</div>
							</template>
						</el-table-column>
						
						<el-table-column align="center" label="拿货价">
							<template slot-scope="scope">
								<div>{{scope.row.nhj}}</div>
							</template>
						</el-table-column>
						
						<el-table-column align="center" label="状态">
							<template slot-scope="scope">
								<div style="background: #00cf33;color: #fff;" v-if="scope.row.jczt == 1">物料入库</div>
								<div style="background: #cf0003;color: #fff;" v-else>物料出库</div>
							</template>
						</el-table-column>

						<el-table-column align="center" label="操作时间">
							<template slot-scope="scope">
								<div>{{scope.row.create_time.slice(0, 10)}}</div>
							</template>
						</el-table-column>
						
					</el-table>
				</div>
			</el-form-item>
			
		</el-form>
		<el-button type="primary" style="position: fixed;bottom: 50px;right: 50px;" @click="submit">提交</el-button>
	</div>
</template>

<script>
export default {
	inject: ['app', 'layout'],
	data() {
		return {
			id: 0,
			cates: [],
			express: [],
			tableData:[],
			// xin:['【上新】','【必看】','【热门】'],
			form: {
				mc: '',
				bzq: "",
				num: 0,
				nhj: 0,
				yj:10,
				nums:0,
				jczt: 1,
				nhjs:0
			},
			options: [
				{
					value: 0,
					label: '6小时'
				},
				{
					value: 1,
					label: '12小时'
				},
				{
					value: 2,
					label: '24小时'
				},
				{
					value: 3,
					label: '2天'
				},
				{
					value: 4,
					label: '3天'
				},
				{
					value: 5,
					label: '15天'
				},
				{
					value: 6,
					label: '30天'
				},
				{
					value: 7,
					label: '当天打烊'
				}
			]
		};
	},
	// 过滤器 文章无限级分类
	filters: {
		tree(item) {
			if (item.level == 0) {
				return item.name;
			}
			let str = '';
			for (let i = 0; i < item.level; i++) {
				str += i == 0 ? '|--' : '--';
			}
			return str + ' ' + item.name;
		}
	},
	// 生命周期
	created() {
		// 获取id
		this.id = this.$route.params.id;
		if (this.id) {
			this.layout.showLoading();
			this.axios.get('/admin/cklb/read/' + this.id, {token: true})
				.then(res => {
					let data = res.data.data;
					delete data.create_time;
					delete data.update_time
					this.form = data;
					this.form.nums = 0;
					this.form.nhjs = 0;
					this.layout.hideLoading();
				})
				.catch(err => {
					this.layout.hideLoading();
				});
		}
	},
	methods: {
		//提交
		submit() {
			this.layout.showLoading();
			let url = this.id ? '/admin/cklb/' + this.id : '/admin/cklb';
			if(!this.form.jczt){
				this.form.jczt = 3
			}else{
				this.form.jczt = parseInt(this.form.jczt)
			}
			
			this.axios
				.post(url, this.form, {
					token: true
				})
				.then(res => {
					this.$message({
						type: 'success',
						message: this.id ? '修改成功' : '添加成功' //如果this.id存在,就是修改成功,否则就是发布成功
					});
					console.log(res.data.data)
					this.$router.push({
						name: 'cklb_list_list'
					});
					this.layout.hideLoading();
				})
				.catch(err => {
					this.layout.hideLoading();
				});
		}
	}
};
</script>

<style>
.goods_create .el-form-item {
	margin-bottom: 15px;
}
.el-input.is-disabled .el-input__inner{
	background-color: #fff
}
</style>
